:root {
  --primary-color: #322e71;
  --table-header-bg: #158d94;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #d4efe8;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}


#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}

.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}

.ant-input-number,
.ant-picker {
  width: 100% !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #134475;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

::-webkit-scrollbar {
  width: 8px;
}

@media all and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-form-item-label>label {
  color: rgba(0, 0, 0, 0.85) !important;
}

/* .anticon .anticon-close{ */
/* position: absolute !important;
color: #5383d3 !important;
right:2;
top: 2; */
/* } */
/* .ant-drawer-header-title{
  flex: 1 !important;
  color: #75b2f5 !important;
  position:absolute !important;
  align-items: center !important;
} */

/* .ant-btn .ant-btn-primary .ant-btn-dangerous{
  background-color: red !important;
} */

.form-card {
  background: #3c3c3c !important;
  border: none !important;
}

.form-card .ant-card-head {
  border-bottom: 1px solid #646464 !important;
}

.form-card .ant-card-head-title {
  color: white !important;
}

.form-card .ant-empty-description {
  color: white !important;
}

.form-card .ant-empty-img-default-path-5 {
  display: none !important;
}

.form-card .ant-empty-img-default-g {
  display: none !important;
}

.sbEntities-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: #d7d7d74d;
}

#sbEntities>div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
}

#sbEntities:hover>div:last-child {
  opacity: 1;
}

.access_rights_form label {
  color: black !important;
}

.ant-form-item-label>label:after {
  content: "" !important;
}

.access_rights_form .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}

.access_rights_form .ant-form-item-control {
  flex: 1;
}

.horizontal_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontal_container::-webkit-scrollbar {
  display: none;
}

.ant-form .ant-form-vertical .user_form {
  color: #111;
}

.user_form label {
  color: #134475 !important;
}

.checkout-tabs {
  margin-left: 3rem;
  margin-right: 3rem;
}

.ant-form-horizontal .ant-form-item-label {
  width: 45% !important;
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title {
  color: #fff;
}

.ant-drawer-close {
  color: #fff;
}

.date-pos {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px !important;
}

.ant-btn .ant-btn-primary {
  background-color: #134475 !important;
}

/* .file-upload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
} */

.ant-table-thead .ant-table-cell {
  background-color: #158d94 !important;

  color: var(--table-header-color) !important;
}

.ant-table-tbody .ant-table-row {
  background-color: var(--table-odd-row-bg);
}

.ant-table-tbody .ant-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.ant-table-tbody .ant-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.ant-table-thead .ant-table-cell {
  border-right: 1px solid #158d94 !important;
}

.ant-table-thead .ant-table-cell:last-child {
  border-right: 0px !important;
}

.ant-table-tbody .ant-table-cell {
  border-right: 1px solid #d5d5d5;
}

.ant-table-tbody .ant-table-cell:last-child {
  border-right: 0px !important;
}

.ant-table-tbody .ant-table-row .ant-table-cell-row-hover {
  background-color: #f0f0f0 !important;
}

.ant-table-cell {
  padding-left: 20px;

  padding-right: 20px;

  height: 40px;
}

.ant-table-thead {
  background-color: #158d94;
}

tbody tr:nth-child(odd) {
  background-color: #d4efe8;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-menu-item-selected {
  font-size: large;
  font-weight: bold;
  color: white !important;
  background-color: #ffffff2a !important;
}

.ant-menu-item {
  color: white !important;
}

.ant-menu-item-active {
  font-size: large;
  font-weight: bold;
  color: white !important;
  background-color: #ffffff2a !important;
}

.ant-menu-item-active .ant-menu-item-icon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-item-active .anticon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-item-selected .anticon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-submenu {
  color: white !important;
}

.ant-menu-submenu-arrow {
  color: white !important;
}

.ant-menu-submenu-active {
  font-size: large;
  font-weight: bold;
  color: white !important;
  background-color: #ffffff2a !important;
}

.ant-menu-sub {
  background-color: #ffffff2a !important;
}

.ant-menu-submenu-active .ant-menu-item-icon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-submenu-selected .ant-menu-item-icon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-submenu-active .anticon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-menu-submenu-selected .anticon {
  font-size: large !important;
  font-weight: bold !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:last-child {
  display: none !important;


}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 402px !important;
}

.ant-picker-footer-extra>div {
  flex-wrap: wrap !important;
}

.sticky-name {
  position: sticky;
  top: 0;
  background-color: #8be5ec;
  z-index: 1;
  border-radius: 10px;
  /* width: fit-content; */
  /* margin-left: auto;
  margin-right: auto;
  padding: 30; */
}

table {
  border-collapse: collapse;

  width: 100%;
}

td {
  border: 1px solid #dddddd;

  text-align: left;

  padding: 8px;

  color: #111;
}

th {
  border: 1px solid #dddddd;

  text-align: center;

  padding: 8px;

  color: #ffffff;
}

thead {
  background-color: #158d94;
}

tbody tr:nth-child(odd) {
  background-color: #d4efe8;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.change-password-back-btn:hover {
  color: dodgerblue;
  text-decoration: underline;
}

.center-align-header .ant-table-column-title {
  text-align: center;
}

.gl-mismatch-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.text-center {
  width: 100%;
  width: max-content;
  text-align: center;
}

.flex {
  display: flex;
}

.gl-mismatch-header {
  margin-top: 20px;
  text-align: center;
}

.gl-mismatch-agree {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-right {
  text-align: right;
}

.justify-last {
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.change-password-society{
  margin: 2px;
}

.submit-buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.flex{
  display:flex !important;
} 
.align-center{
  align-items:center !important;
} 
.justify-between{
  justify-content: space-between !important;
}
.justify-flex-end{
  justify-content: flex-end !important;
}

.show_test_credential_button{
  background-color: #339381 !important;
  color: white !important;
}

.department-container {
  width: 95%;
  padding: 20px;
}

.log-list {
  list-style: none;
  padding: 0;
  margin: 20px auto;
  width: 100%;
}

.log-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.row-number {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.message {
  text-align: center;
  word-break: break-word;
  width: 100%;
}

.tickets-search-section{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px !important;
  gap:5px !important
}
.css-view-1dbjc4n .r-flexDirection-18u37iz .r-justifyContent-17s6mgv{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px !important;
}

.ticket-raising-pagination{
  margin-top: 15px;
}

.issue-info-warning{
  margin: 2px 0px 18px;
}

/* Change text color for disabled Input */
.ant-input[disabled] {
  color: black !important; /* Example: Red text for disabled Input */
  background-color: #f5f5f5 !important; /* Optional: Adjust background if needed */
}

/* Change text color for disabled Select */
.ant-select-disabled .ant-select-selector {
  color: black !important; /* Example: Red text for disabled Input */
  background-color: #f5f5f5 !important;
}

/* Change text color for disabled InputNumber */
.ant-input-number[disabled] .ant-input-number-input {
  color: black !important; /* Example: Red text for disabled Input */
  background-color: #ffffff !important;
}

/* Change text color for disabled TextArea */
.ant-input[disabled]::placeholder {
  color: black !important; /* Placeholder color */
  background-color: #ffffff !important;
}


.custom-disabled-input {
  background-color: #f6f6f6 !important; /* Light gray background */
  color: #000000 !important;            /* Grey text */
}

.attachments{
  margin: 0px 0px 8px 0px;
}

/* Target only specific dropdowns by class */
.custom-disabled-dropdown .ant-select-disabled .ant-select-selector {
  color: black !important; /* Custom text color */
  background-color: #f5f5f5 !important; /* Optional: Custom background */
  pointer-events: auto;
}
.dashboard-container {
  padding: 16px;
}

.dashboard-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.dashboard-card {
  flex: 0 0 32%; 
  min-width: 280px;
  min-height: 90px;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px; 
  cursor:pointer !important;
}

.dashboard-icon {
  font-size: 2em;
  margin-bottom: 8px;
}

.dashboard-title {
  margin: 0 0 8px;
  font-size: 1.2em;
}

.dashboard-count {
  font-size: 1.6em;
  font-weight:bolder;
}

/* Gradient Backgrounds */
.bg-gradient-blue {
  background: linear-gradient(135deg, #86acd0, #2976c3);
}

.bg-gradient-yellow {
  background: linear-gradient(135deg, #b8a16b, #bca95d);
}

.bg-gradient-red {
  background: linear-gradient(135deg, #ca4343, #da6a6a);
}

.bg-gradient-green {
  background: linear-gradient(135deg, #67a567, #4e934e);
}

.bg-gradient-purple {
  background: linear-gradient(135deg, #a28eb6, #7752ae);
}

.bg-gradient-pink {
  background: linear-gradient(135deg, #b368b3, #b965a8);
}

.bg-gradient-orange {
  background: linear-gradient(135deg, #fdcb70, #e2a74f);
}

.bg-gradient-teal {
  background: linear-gradient(135deg, #8bbac6, #57bdd7);
}

.dashboard-grid > .dashboard-card:nth-last-child(2),
.dashboard-grid > .dashboard-card:nth-last-child(1) {
  margin-left: 0;
  flex: 0 0 32%; 
}
